import FilterSelectOption from "./FilterSelectOption";
import "./FilterMultiSelect.scss"
import { useEffect, useRef, useState } from "react";
import FilterSearchBar from "./FilterSearchBar";
import FilterCollapsable from "./FilterCollapsable";

function FilterMultiSelect({ options, onSelect, onFocus, focusOptionValue, parentfocusOptionValue }) {
  const [searchValue, setSearchValue] = useState("");
  const collapseLength = 5;

  // on parentfocusOptionValue change
  // update focusOptionValue to the first selected option
  // and reset search
  const prevParentfocusOptionValueRef = useRef();
  useEffect(() => {
    if (prevParentfocusOptionValueRef.current !== parentfocusOptionValue) {
      onFocus && onFocus(options?.filter(o => o.isSelected)[0]?.value)
      setSearchValue("");
      prevParentfocusOptionValueRef.current = parentfocusOptionValue;
    }
  }, [parentfocusOptionValue, onFocus, options])

  return (
    <div className="comp-filter-multi-select">
      <FilterCollapsable label={options?.length > collapseLength ? "selected" : ""} isCollapsable={(options?.length > collapseLength)}>
        {options?.filter(o => ((options?.length > collapseLength) ? o.isSelected : true) && o.title.toLowerCase().includes(searchValue.toLowerCase())).map((o) => (
          <FilterSelectOption
            key={o.value}
            title={o.title}
            isSelected={o.isSelected}
            isFocusOption={focusOptionValue === o.value}
            onFocus={(o.isSelected && onFocus) ? (() => onFocus(o.value)) : null}
            onSelect={() => onSelect(o.value)}
          />
        ))}
      </FilterCollapsable>
      {
        (options?.length > collapseLength) &&
        <FilterCollapsable label={"all"} isCollapsable>
          {
            options?.filter((o) => o.title.toLowerCase().includes(searchValue.toLowerCase())).map((o) => (
              <FilterSelectOption
                key={o.value}
                title={o.title}
                isSelected={o.isSelected}
                isFocusOption={focusOptionValue === o.value}
                onSelect={() => onSelect(o.value)}
              />
            ))
          }
        </FilterCollapsable>
      }
      {
        (options?.length > collapseLength) &&
        <FilterSearchBar
          value={searchValue}
          onChange={(value) => { setSearchValue(value) }}
        />
      }
    </div>
  )
}

export default FilterMultiSelect