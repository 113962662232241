import "./styles/global.scss";
import "./styles/reset.scss";
import './App.css';
import LoginPage from "./pages/LoginPage";
import Dashboard from "./templates/Dashboard";
import { createContext, useContext, useEffect, useState } from "react";
import config from "./config";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import OrdersSection from "./pages/OrdersSection";
import NewOrderSection from "./pages/NewOrderSection";
import ErrorPage from "./pages/ErrorPage";
import OrderDataSection from "./pages/OrderDataSection";
import PaymentSection from "./pages/PaymentSection";
import PaymentConfirmSection from "./pages/PaymentConfirmSection";
import AllOrdersSection from "./pages/AllOrdersSection";
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient();

const HomeRedirect = () => {
  const navigate = useNavigate();
  const { setAppGlobalData } = useContext(appGlobalContext);

  // fetch user
  useEffect(() => {
    fetch(config.API_BASE + "/auth/user")
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          console.log(res.error)
          navigate("/login");
        } else {
          setAppGlobalData(data => ({ ...data, user: res }))
          navigate("/dashboard/orders");
        }
      })
  }, [navigate, setAppGlobalData])

  return <p>checking auth</p>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeRedirect />,
  },
  {
    path: "/login",
    element: <LoginPage />
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "orders",
        element: <OrdersSection />,
      },
      {
        path: "orders/all",
        element: <AllOrdersSection />
      },
      {
        path: "orders/new",
        element: <NewOrderSection />
      },
      {
        path: "orders/:orderId/data",
        element: <OrderDataSection />
      },
      {
        path: "orders/:orderId/pay",
        element: <PaymentSection />
      },
      {
        path: "orders/:orderId/confirm",
        element: <PaymentConfirmSection />
      },
    ]
  },
]);

export const appGlobalContext = createContext(null);

function App() {
  const [appGlobalData, setAppGlobalData] = useState({});

  return (
    <appGlobalContext.Provider value={{ appGlobalData, setAppGlobalData }}>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </div>
    </appGlobalContext.Provider>
  );
}

export default App;
