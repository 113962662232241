import FilterLabel from "./FilterLabel";
import FilterNumberInput from "./FilterNumberInput";
import FilterRangePicker from "./FilterRangePicker";

function FilterRange({ onChange, start, end, min, max, value, isExactValue }) {
  return (
    <div className="comp-filter-range" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div className="range">
        <FilterRangePicker
          min={min}
          max={max}
          start={isExactValue ? 0 : start}
          end={end}
          value={value}
          onChange={(value, valueType) => { onChange(value, valueType) }}
          isExactValue={isExactValue}
        />
      </div>
      <div style={{ display: "flex", gap: "10px", width: "100%" }}>
        {
          isExactValue &&
          <div className="number-input" style={{ width: "100%" }}>
            <FilterNumberInput
              value={value}
              min={start}
              max={end}
              onChange={(value) => { onChange(value, "value") }}
            />
          </div>
        }
        {
          !isExactValue &&
          <>
            < div className="number-input" style={{ width: "100%" }}>
              <FilterLabel title={`Min (≥${start})`} />
              <FilterNumberInput
                value={min}
                min={start}
                max={max}
                onChange={(value) => { onChange(value, "min") }}
              />
            </div>
            <div className="number-input" style={{ width: "100%" }}>
              <FilterLabel title={`Max (≤${end})`} />
              <FilterNumberInput
                value={max}
                min={min}
                max={end}
                onChange={(value) => { onChange(value, "max") }}
              />
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default FilterRange