import "./FilterRangePicker.scss"
import FilterTriangle from "./FilterTriangle";

function FilterRangePicker({ min, max, start, end, value, isExactValue }) {
  const minPercent = Math.round((min - start) * 100 / (end - start));
  const maxPercent = Math.round((max - start) * 100 / (end - start));
  const valuePercent = Math.round((value - start) * 100 / (end - start));

  return (
    <div className="comp-filter-range-picker">
      <div className="picker">
        {
          isExactValue &&
          <div className="tag" style={{ "--percent": `${valuePercent}%` }}>
            <div className="number">
              {value}
            </div>
            <div className="arrow">
              <FilterTriangle />
            </div>
          </div>
        }
        {
          !isExactValue &&
          <>
            <div className="tag" style={{ "--percent": `${minPercent}%` }}>
              <div className="number">
                {min}
              </div>
              <div className="arrow">
                <FilterTriangle />
              </div>
            </div>
            <div className="tag" style={{ "--percent": `${maxPercent}%` }}>
              <div className="number">
                {max}
              </div>
              <div className="arrow">
                <FilterTriangle />
              </div>
            </div>
          </>
        }
      </div>
      <div className="slider">
        <div className="container">
          {
            isExactValue &&
            <>
              <div className="area" style={{ "--minPercent": `${0}%`, "--maxPercent": `${valuePercent}%` }}></div>
              <div className="line" style={{ "--percent": `${valuePercent}%` }}></div>
            </>
          }
          {
            !isExactValue &&
            <>
              <div className="area" style={{ "--minPercent": `${minPercent}%`, "--maxPercent": `${maxPercent}%` }}></div>
              <div className="line" style={{ "--percent": `${minPercent}%` }}></div>
              <div className="line" style={{ "--percent": `${maxPercent}%` }}></div>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default FilterRangePicker